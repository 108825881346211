










































import { RosterOption, RosterSubRequirement, RosterType, SchoolOrgType } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { UnreachableCaseError } from 'ts-essentials'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'RosterOptionsMenu'
})
export default class RosterOptionsMenu extends Mixins(ComponentHelper) {
  @Watch('options', { immediate: true })
  onOptionsChanged (val: RosterOption | undefined) {
    console.log('test')
    this.mOptions = val ?? null
  }

  @Prop({ required: true }) options!: RosterOption | undefined

  @Prop({ required: true }) type!: RosterType

  mOptions: RosterOption | null = null

  SchoolOrgType = SchoolOrgType

  rosterOptionToggled () {
    this.$emit('option-toggled')
  }

  rosterTypeTitleFromKey (key: RosterType) {
    switch (key) {
      case 'wonde':
        return 'Wonde'
      case 'clever':
        return 'Clever'
      case 'gg4l':
        return 'GG4L'
      case 'classlink':
        return 'ClassLink'
      default:
        throw new UnreachableCaseError(key)
    }
  }

  anySubToggled (val: boolean) {
    if (val) {
      this.mOptions!.subscription_requirement = null
    } else {
      this.mOptions!.subscription_requirement = []
    }
  }

  subOptionToggled (val: boolean, subType: RosterSubRequirement) {
    if (this.options?.subscription_requirement === null) {
      this.options.subscription_requirement = []
    }

    if (val) {
      this.options!.subscription_requirement!.push(subType)
    } else {
      this.options!.subscription_requirement = this.options!.subscription_requirement.filter(s => s !== subType)
    }
  }
}
