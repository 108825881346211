























































































import { Api, calculateShippingTax, calculateTaxForInvoice, CartItem, CountryInfo, EditDraftPurchaseRequest, fromFinancialString, isValidVatNumber, MerchPurchase, MerchPurchaseItem, SchoolModel } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import _ from 'lodash'

@Component({ name: 'EditMerchInvoice' })
export default class EditMerchInvoice extends Mixins(ComponentHelper) {
  @Prop({ required: true }) order!: MerchPurchase

  data: MerchPurchaseItem[] = []

  shippingCost: number | null = null

  school: SchoolModel | null = null

  countries: CountryInfo[] = []

  mounted () {
    this.data = _.cloneDeep(this.order.items)
    this.shippingCost = this.order.shipping_cost ? fromFinancialString(this.order.shipping_cost) : null

    this.getSchool()
    this.getCountries()
  }

  get subtotal () {
    return this.data.reduce((sub, item) => sub + (fromFinancialString(item.price) * item.quantity), 0)
  }

  get tax () {
    const billingCountry = this.countries.find(c => c.code === this.order.billing.country_code)

    const shouldReverseCharge = billingCountry?.reverse_charge === true && isValidVatNumber(this.school?.vat_number ?? null)

    const cart: CartItem[] = this.data.map(i => ({
      item: i.storeItem,
      qty: i.quantity
    }))

    const shippingTax = this.shippingCost ? calculateShippingTax(cart, this.order.billing, this.shippingCost, this.school?.tax_exempt === true, shouldReverseCharge) : 0
    const sale: MerchPurchase = { ...this.order, items: this.data }
    const cartTax = calculateTaxForInvoice(sale, this.school?.tax_exempt === true, shouldReverseCharge)

    return shippingTax + cartTax
  }

  get total () {
    return this.subtotal + this.tax + (this.shippingCost === null ? 0 : this.shippingCost)
  }

  async save () {
    try {
      const editData: EditDraftPurchaseRequest = {
        lines: this.data.map(i => ({
          id: i.id,
          name: i.name,
          quantity: i.quantity,
          price: fromFinancialString(i.price)
        })),
        shipping_cost: this.shippingCost ?? undefined
      }

      const purchase = await Api.editDraftInvoice(this.order.id, editData)

      this.$buefy.toast.open({
        message: 'Invoice updated.',
        type: 'is-success',
        position: 'is-bottom',
        duration: 3000
      })

      this.$emit('saved', purchase)
      this.$emit('close')
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000
        })
      }
    }
  }

  async getSchool () {
    try {
      if (this.order.school_id) {
        this.school = await Api.getSchool(this.order.school_id)
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000
        })
      }
    }
  }

  async getCountries () {
    try {
      this.countries = await Api.getCountries()
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000
        })
      }
    }
  }
}
